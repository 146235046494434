export enum ROUTE_PATHS {
  OVERVIEW = '/provider/',
  CALENDAR_OVERVIEW = '/provider/overview',
  LOGIN = '/login',
  CHOOSE_COMPANY = '/provider/choose-company',
  REGISTER = '/register',
  VERIFY_NEW_USER = '/verify-new-user',
  RESET_PASSWORD = '/reset-password',
  SET_NEW_PASSWORD = '/set-new-password',
  INVITED_USER = '/invited-user',
  CLIENTS = '/provider/clients',
  CLIENT = '/provider/clients/:id',
  TASKS = '/provider/tasks',
  TASK = '/provider/tasks/:id',
  SERVICES = '/provider/services',
  SERVICE = '/provider/services/:id',
  COMPUCASH_IMPORT = '/provider/services/compucash-import',
  ADDITIONAL_SERVICES = '/provider/additional-services',
  ADDITIONAL_SERVICE = '/provider/additional-services/:id',
  RESOURCES = '/provider/resources',
  RESOURCE = '/provider/resources/:id',
  RESOURCE_GROUPS = '/provider/groups',
  BOOKKEEPING = '/provider/bookkeeping',
  REPORTS = '/provider/reports',
  SALES_REPORT = '/provider/reports/sales',
  DEPOSIT_REPORT = '/provider/reports/deposit',
  PARTNERS_REPORT = '/provider/reports/partners',
  STATISTICS_REPORT = '/provider/reports/statistics',
  CHANNEL_MANAGER = '/provider/channel-manager', // TODO: TOUR-118: Deprecated
  CHANNEL_MANAGER_LIST = '/provider/channels',
  CHANNEL_MANAGER_DETAILS = '/provider/channels/:uuid',
  CHANNEL_MANAGER_BOOKING_WIDGET = '/provider/channels/booking-widget',
  SETTINGS = '/provider/settings',
  SETTINGS_GENERAL = '/provider/settings/general',
  SETTINGS_PROFILE = '/provider/settings/profile',
  SETTINGS_LOCATION = '/provider/settings/location',
  SETTINGS_ROLES = '/provider/settings/roles',
  SETTINGS_USERS = '/provider/settings/users',
  SETTINGS_INTEGRATIONS = '/provider/settings/integrations',
  SETTINGS_EMAILS = '/provider/settings/emails',
  SETTINGS_NOTIFICATIONS = '/provider/settings/emails/notifications',
  SETTINGS_TEMPLATES = '/provider/settings/emails/templates',
  SETTINGS_CHECKIN = '/provider/settings/checkin',
  SETTINGS_CONTRACTS = '/provider/settings/contracts',
  SETTINGS_GOOGLE_CALENDAR = '/provider/settings/google-calendar',
  SETTINGS_COMPUCASH = '/provider/settings/compucash',
  SUPPORT = '/provider/support',
  SUPPORT_CONTACT = '/provider/support/contact',
  SUPPORT_HELP = '/provider/support/help',
  STYLESHEET = '/provider/stylesheet',
  CALENDAR = '/provider/calendar',
  BOOKINGS = '/provider/bookings',
  BOOKING = '/provider/bookings/:id',
  TICKET_LIST = '/provider/tickets',
  TICKET_LIST_VALIDATION = '/provider/tickets/validation',
  TICKET_VALIDATION = '/provider/ticket-validation/:uuid/',
  INVOICES = '/provider/invoices/',
  // Separate paths for provider for separate layout usage.
  TERMS = '/provider/terms/',
  PRIVACY_POLICY = '/provider/privacy-policy/',
  // Partner company and invite endpoints
  PARTNER_COMPANY_LIST = '/provider/settings/partners',
  PARTNER_COMPANY_DETAILS = '/provider/settings/partners/:id',
  PARTNER_COMPANY_INVITE_LIST = '/provider/settings/partner/invites',
  // Details is not provider specific on purpose
  PARTNER_COMPANY_INVITE_DETAILS = '/partner/invites/:uuid',
}

export default { ROUTE_PATHS }
