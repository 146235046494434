import { ServiceHashId } from '@/models/public/service.model'
import axios from 'axios'
import { defineStore } from 'pinia'
import { RouteLocationNormalized } from 'vue-router'

export interface ITheme {
  logo:
    | {
        file: string
      }
    | undefined
}

interface IState {
  theme: ITheme
}

const getDefaultTheme = (): ITheme => {
  return {
    logo: undefined,
  }
}

export const useThemeStore = defineStore('theme', {
  state: (): IState => ({
    theme: getDefaultTheme(),
  }),
  getters: {
    getLogoSrc(state): string | undefined {
      return state.theme.logo?.file
    },
  },
  actions: {
    $reset() {
      console.log('resetting theme')
      this.theme = getDefaultTheme()
    },
    // Not used yet
    // async applyCompanyTheme(companyUuid: string): Promise<void> {
    //   return axios
    //     .get<ITheme>(`public/companies/${companyUuid}/theme/`)
    //     .then((response) => {
    //       if (process.env.NODE_ENV !== 'production') {
    //         console.log('Applying provider theme')
    //       }
    //       this.theme = response.data
    //     })
    //     .catch((error) => {
    //       console.error('Failed to apply company theme:', error)
    //       this.$reset()
    //     })
    // },
    async applyServiceCompanyTheme(serviceId: ServiceHashId): Promise<void> {
      return axios
        .get<ITheme>(`public/service/${serviceId}/company-theme/`)
        .then((response) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Applying service provider theme')
          }
          this.theme = response.data
        })
        .catch((error) => {
          console.error('Failed to apply service company theme:', error)
          this.$reset()
        })
    },
  },
})

export const applyProviderTheme = async (to: RouteLocationNormalized) => {
  const themeStore = useThemeStore()
  if (to.meta.serviceProviderThemeEnabled) {
    const paramProperty = <string | undefined>(
      to.meta.serviceProviderThemeRouteParam
    )
    if (paramProperty !== undefined) {
      const serviceId = <ServiceHashId>to.params[paramProperty]
      await themeStore.applyServiceCompanyTheme(serviceId)
      return
    }
    console.error('route.meta.serviceProviderThemeRouteParam is unset')
  }
  themeStore.$reset()
}
