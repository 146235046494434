import axios, { InternalAxiosRequestConfig } from 'axios'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { VueQueryPlugin } from 'vue-query'
// Sidebar menu
import AlertBanner from '@/components/AlertBanner.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import BaseInput from '@/components/BaseInput.vue'
import BaseTag from '@/components/BaseTag.vue'
import DatePicker from '@/components/DatePicker.vue'
import InfoHelper from '@/components/InfoHelper.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import InputErrorMessages from '@/components/misc/InputErrorMessages.vue'
import RequiredAsterisk from '@/components/misc/RequiredAsterisk.vue'
import ModalWrapper from '@/components/ModalWrapper.vue'
import TranslatedContent from '@/components/public/TranslatedContent.vue'
import { useAuthStore } from '@/store/auth'
import { applyProviderTheme } from '@/store/theme'
import BookingWidgetView from '@/views/public/BookingWidgetView.vue'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import Vue3TouchEvents from 'vue3-touch-events'
import App from './App.vue'
import './assets/scss/style.scss'
import i18n from './i18n'
import router from './router'
import { toast403orDefault } from './utils/errors'

axios.defaults.baseURL =
  process.env.VUE_APP_API_URL || 'http://localhost:8000/api/v1/'
axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem(
  'tour-token',
)}`
axios.defaults.headers.common['Accept'] = 'application/json'

axios.defaults.paramsSerializer = {
  /* This affects list query variables.
    Default settings (indexes: false) would parse { services: [1, 2, 3] } as:
    "?services[]=1&services[]=2&services[]=3"
    By setting it to null we changed it to:
    "?services=1&services=2&services=3".
  */
  indexes: null,
}

axios.interceptors.request.use(
  // @ts-ignore Necessary because it doesn't like the added 'Accept-Language' as it is not defined in InternalAxiosRequestConfig.
  function (config: InternalAxiosRequestConfig) {
    // Can't do i18n.global.locale.value because TS thinks type is string when it is ComputedRef<string>
    const localeRef: any = i18n.global.locale
    const locale = localeRef.value
    return {
      ...config,
      headers: {
        'Accept-Language': locale,
        ...config.headers,
      },
    }
  },
  function (error) {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === 401) {
      const authStore = useAuthStore()
      authStore.logoutLocallyAndRedirectToLogin(window.location.pathname)
    }

    return Promise.reject(error)
  },
)

const app = createApp({
  render() {
    // Check the current path
    const path = window.location.pathname

    // If the path is '/booking-widget', render the BookingComponent
    if (path === '/booking-widget') {
      return h(BookingWidgetView)
    }

    // Otherwise, render the main App component
    return h(App)
  },
})

app.component('BaseCard', BaseCard)
app.component('BaseInput', BaseInput)
app.component('BaseButton', BaseButton)
app.component('BaseTag', BaseTag)
app.component('DatePicker', DatePicker)
app.component('LoadingSpinner', LoadingSpinner)
app.component('InfoHelper', InfoHelper)
app.component('AlertBanner', AlertBanner)
app.component('RequiredAsterisk', RequiredAsterisk)
app.component('InputErrorMessages', InputErrorMessages)
app.component('ModalWrapper', ModalWrapper)
app.component('TranslatedContent', TranslatedContent)
// Custom directive to handle click outside of element
app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutside = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el)
      }
    }
    document.addEventListener('click', el.clickOutside)
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutside)
  },
})

app
  .use(router)
  .use(Toast)
  .use(VueSidebarMenu)
  .use(createPinia())
  .use(i18n)
  .use(VueQueryPlugin)
  .use(VueViewer)
  .use(Vue3TouchEvents)
  .use(FloatingVue)
  .use(toast403orDefault)

router.beforeResolve(async (to) => {
  applyProviderTheme(to)
})
router.isReady().then(() => app.mount('#app'))
