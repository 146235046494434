import { PublicService } from '@/models/public/bookingWidget.model'
import {
  CompanyHousingDateAvailability,
  CompanyHousingDateAvailabilityRequest,
  EventTimeSlot,
  ExperienceTimeslot,
} from '@/models/public/service.model'
import axios from 'axios'
import { useMutation, useQuery } from 'vue-query'

// https://tkdodo.eu/blog/effective-react-query-keys
const bookingWidgetKeys = {
  all: ['bookingWidget'] as const,
  companyLogo: (idToken: string) =>
    [...bookingWidgetKeys.all, 'companyLogo', { idToken }] as const,
}

export function usePublicCompanyLogoQuery(idToken: string) {
  return useQuery(
    bookingWidgetKeys.companyLogo(idToken),
    async (): Promise<any> => {
      const { data } = await axios.get<any>(`public/companies/${idToken}/logo/`)
      return data
    },
    { retry: 0 },
  )
}

export function useServicesFetchMutation() {
  return useMutation(async (idToken: string) => {
    const { data } = await axios.get<PublicService[]>(
      `public/companies/${idToken}/services/`,
    )
    return data
  })
}

export function useFetchDatesAvailability() {
  return useMutation(async (payload: CompanyHousingDateAvailabilityRequest) => {
    const { data } = await axios.post<CompanyHousingDateAvailability[]>(
      `/public/housings/dates-availability/`,
      {
        ...payload,
        // Undefined because it has user friendlier error message compared to empty string and null (for dates)
        start: payload.start || undefined,
        end: payload.end || undefined,
      },
    )
    return data
  })
}

export function useServicesSlotsFetchMutation() {
  return useMutation(
    async ({
      serviceId,
      body,
    }: {
      serviceId: string
      body: { start: string; end: string; group_size: number }
    }) => {
      const { data } = await axios.post<ExperienceTimeslot[]>(
        `public/service/${serviceId}/experience-detailed-availability/minimum-slots`,
        body,
      )
      return data
    },
  )
}

export function useEventsSlotsFetchMutation() {
  return useMutation(
    async ({
      serviceId,
      body,
    }: {
      serviceId: string
      body: { start: string; end: string }
    }) => {
      const { data } = await axios.post<EventTimeSlot[]>(
        `public/service/${serviceId}/event-detailed-availability/period`,
        body,
      )
      return data
    },
  )
}
