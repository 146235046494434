import { StringUuid } from '@/models/shared.model'
import router from '@/router'
import { ROUTE_PATHS } from '@/router/paths'
import i18n from '../i18n'

export enum PROPERTY_CHANNEL_STATUS {
  INACTIVE = 'INACTIVE',
  PROPERTY_ACTIVATED = 'PROPERTY_ACTIVATED',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}

export enum CHANNEL {
  BOOKING = 1,
}

export enum PROPERTY_CHANNEL_TABS {
  LIST = 'property-channel-list',
  BOOKING_WIDGET = 'property-channel-booking-widget',
}

export const getPropertyChannelStatusLabel = (
  key: PROPERTY_CHANNEL_STATUS,
): string | PROPERTY_CHANNEL_STATUS => {
  return (
    {
      [PROPERTY_CHANNEL_STATUS.INACTIVE]: i18n.global.t(
        'channels.statusLabel.INACTIVE',
      ),
      [PROPERTY_CHANNEL_STATUS.PROPERTY_ACTIVATED]: i18n.global.t(
        'channels.statusLabel.PROPERTY_ACTIVATED',
      ),
      [PROPERTY_CHANNEL_STATUS.ACTIVE]: i18n.global.t(
        'channels.statusLabel.ACTIVE',
      ),
      [PROPERTY_CHANNEL_STATUS.ERROR]: i18n.global.t(
        'channels.statusLabel.ERROR',
      ),
    }[key] || key
  )
}

export const redirectToList = () => {
  router.push({
    name: ROUTE_PATHS.CHANNEL_MANAGER_LIST,
  })
}

export const redirectToDetails = (uuid: StringUuid) => {
  router.push({
    name: ROUTE_PATHS.CHANNEL_MANAGER_DETAILS,
    params: {
      uuid,
    },
  })
}
