export enum ROUTE_PATHS {
  ORDER = '/order/:uuid/',
  CART = '/order/:uuid/cart/',
  DETAILS = '/order/:uuid/details/',
  TERMS = '/terms/',
  PRIVACY_POLICY = '/privacy-policy/',
  PAYMENT = '/order/:uuid/payment/',
  CANCEL = '/order/:uuid/cancel/',
  SERVICES = '/:id/:slug/',
  ORDER_ITEM_CHECK_IN = '/order/:orderUuid/order-item/:orderItemId/check-in/',
  CHECK_IN = '/check-in/:checkInUuid/',
  BOOKING_WIDGET = '/booking-widget',
}

export default { ROUTE_PATHS }
